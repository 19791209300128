import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface GetResourceResponse<T> {
  message?: string;
  pagination: {
    nextPage: number;
    page: number;
  };
  data: T[];
  totalRecords: number;
  pageCount: number;
  page: number;
  pageSize: number;
  orderBy: Record<string, "asc" | "desc">[];
}

export interface GetResourceInput {
  path: string;
  displayEnvironment: string | null;
  filters?: Record<
    string,
    string | number | boolean | object | undefined | null
  >;
  sort?: Record<string, "ascending" | "descending" | null>;
  pagination?: {
    pageSize?: number | string;
    page?: number | string;
  };
}

export const cleanParams = (
  params: Record<string, string | number | boolean | object | undefined | null>,
) => {
  const cleanedParams: Record<
    string,
    string | number | boolean | object | undefined | null
  > = {};
  Object.keys(params).forEach((key) => {
    if (params[key] == undefined || params[key] === "") {
      return;
    }
    if (typeof params[key] === "object") {
      cleanedParams[key] = JSON.stringify(params[key]);
      return;
    }
    cleanedParams[key] = params[key];
  });
  return cleanedParams;
};

export const getResource = async <T>({
  path,
  filters = {},
  sort,
  pagination,
}: GetResourceInput) => {
  const query = { ...filters };

  if (sort && Object.keys(sort).length > 0) {
    const key = Object.keys(sort)[0];
    const value = sort[key];
    query.order_by = key;
    if (value === "descending") {
      query.desc = "true";
    }
  }

  if (pagination?.pageSize) {
    query.pageSize = pagination.pageSize;
  }

  if (pagination?.page) {
    query.page = pagination.page;
  }

  const { data } = await axios.get<GetResourceResponse<T>>(path, {
    params: cleanParams(query),
  });
  return data;
};

export const RESOURCES_QUERY_KEY = "resources";

export const useResource = <T>(
  input: GetResourceInput,
  resourceKey: string,
  options?: Omit<
    UseQueryOptions<GetResourceResponse<T>, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [resourceKey, input],
    queryFn: () => getResource<T>(input),
    ...options,
  });
