import React from "react";
import { useDeepCompareEffect, useSetState } from "ahooks";
import classNames from "classnames";

import CompareReview from "components/reusable/CompareReview";
import DocVReview from "components/reusable/DocumentVerificationReview/DocVReview";
import IDCardReview from "components/reusable/IDCardReview";
import SpoofReview from "components/reusable/SpoofReview";
import ErrorAndMessageBar from "./error_and_message_bar";

const ReviewContainer = {
  compare: CompareReview,
  id_card: IDCardReview,
  docv: DocVReview,
  spoof: SpoofReview,
};

function ReviewsShow({ reviews, refetchReviews }) {
  const [state, setState] = useSetState({
    error: null,
    message: null,
  });

  useDeepCompareEffect(() => {
    // when data refreshs scroll to the top
    window.scrollTo(0, 0);
  }, [reviews]);

  const onReviewSubmitted = (resp) => {
    if (resp.reviewsAndCount) {
      setState({
        error: resp.reviewsAndCount.error,
        message: resp.reviewsAndCount.message,
      });
    } else if (resp.error) {
      setState({
        error: resp.error,
      });
    } else {
      setState({
        message: resp.message,
      });
    }
  };

  if (reviews.length === 0) {
    return (
      <div>
        <ErrorAndMessageBar messages={state.message} errors={state.error} />
        No reviews were found.
      </div>
    );
  }

  const review = reviews[0];
  const ReviewFormContainer = ReviewContainer[review.purpose];
  const parentClass = classNames("review", {
    "open-review": review.purpose === "id_card" || review.purpose === "docv",
    "spoof-container review": review.purpose === "spoof",
  });

  return (
    <div className={parentClass}>
      <ErrorAndMessageBar messages={state.message} errors={state.error} />
      <ReviewFormContainer
        review={review}
        next={refetchReviews}
        callback={onReviewSubmitted}
        completed={review.complete}
      />
    </div>
  );
}

export default ReviewsShow;
