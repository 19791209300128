import React, { useState } from "react";

import { ReviewCreateResponse, useReviewCreate } from "mutations";
import type { Review } from "types/api/review";
import Carousel from "./carousel";
import ReviewImage from "./review_image";
import TimeoutReview from "./TimeoutReview";

interface Props {
  completed: boolean;
  callback: (response: ReviewCreateResponse) => void;
  next: () => void;
  review: Review;
}

const getImageLabel = (imageURL: string) => {
  if (imageURL.includes("Preview")) return "Selfie";
  if (imageURL.includes("ID_Photo")) return "ID Authority Photo";
  if (imageURL.includes("IDCard")) return "ID Card";

  return "Liveness Check Photo";
};

const SpoofReview: React.FC<Props> = ({
  next,
  review,
  callback,
  completed,
}) => {
  const [fraud, setFraud] = useState<"yes" | "no" | "image_unusable" | null>(
    null,
  );
  const [fraudType, setFraudType] = useState<
    "photo" | "video" | "other" | null
  >(null);
  const [submitted, setSubmitted] = useState(false);
  const reviewCreate = useReviewCreate();

  const handleSubmit = async () => {
    setSubmitted(true);
    const payload = {
      id: review.id,
      fraud: fraud === "yes",
      image_unusable: fraud === "image_unusable",
      fraud_type: fraudType!,
    };

    const resp = await reviewCreate.mutateAsync(payload);
    callback?.(resp);
    next?.();
    setFraud(null);
    setFraudType(null);
  };

  if (!review) {
    return null;
  }

  const carousel: React.JSX.Element[] = [];
  (["source", "service", "targets"] as const).forEach((image) => {
    const rev = review[image];
    if (typeof rev === "string") {
      carousel.push(
        <ReviewImage
          jobID={review.job.job_id}
          key={image}
          label={getImageLabel(rev)}
          source={rev}
          variant="spoof"
          left={image === "source"}
        />,
      );
    } else if (rev) {
      (review[image] as string[]).forEach((imageLink, index) => {
        carousel.push(
          <ReviewImage
            jobID={review.job.job_id}
            key={image}
            label={getImageLabel(imageLink)}
            source={imageLink}
            variant="spoof"
            left={index % 2 !== 0}
          />,
        );
      });
    }
  });

  const idFocus = fraud ? " action--nofocus" : undefined;
  const submitFocus =
    ["no", "image_unusable"].includes(fraud!) || (fraud === "yes" && fraudType)
      ? "active"
      : "not-active";

  let spoofForm;

  if (completed && review.smile_reference !== undefined) {
    let fraudType;
    if (review.smile_reference.internal.Payload.FraudType !== "null") {
      fraudType = `Fraud type: ${review.smile_reference.internal.Payload.FraudType}`;
    }
    const fraud = review.smile_reference.internal.Payload.Fraud === "true";
    spoofForm = (
      <div>
        <p className={fraud ? "failure" : "success"}>
          {review.smile_reference.result.ResultText}
        </p>
        <p>{fraudType}</p>
      </div>
    );
  } else {
    spoofForm = (
      <div className="spoof-form">
        <h1>Is this Fraud?</h1>
        <div className="block--decision decision-yes">
          <div className="block-option thumb">
            <input
              type="radio"
              name="radiogroup1"
              id="rd1"
              value="yes"
              checked={fraud === "yes"}
              onChange={(e) => setFraud(e.target.value as "yes")}
            />
            <label htmlFor="rd1" className="thumb-up">
              Yes
            </label>
            <input
              type="radio"
              name="radiogroup1"
              id="rd2"
              value="no"
              checked={fraud === "no"}
              onChange={(e) => setFraud(e.target.value as "no")}
            />
            <label htmlFor="rd2" className="thumb-down">
              No
            </label>
          </div>
          {fraud === "yes" && (
            <div
              className={`block--sub-decision${fraudType ? " action--nofocus" : " action--focus"}`}
            >
              <h2>what type?</h2>
              <div className="block-option multi">
                <input
                  type="radio"
                  name="radiogroup2"
                  id="type1"
                  value="video"
                  checked={fraudType === "video"}
                  onChange={(e) => setFraudType(e.target.value as "video")}
                />
                <label
                  htmlFor="type1"
                  className="btn-default btn-1 fraud-radio"
                >
                  Video
                </label>
                <input
                  type="radio"
                  name="radiogroup2"
                  id="type2"
                  value="photo"
                  checked={fraudType === "photo"}
                  onChange={(e) => setFraudType(e.target.value as "photo")}
                />
                <label
                  htmlFor="type2"
                  className="btn-default btn-2 fraud-radio"
                >
                  Photo
                </label>
                <input
                  type="radio"
                  name="radiogroup2"
                  id="type3"
                  value="other"
                  checked={fraudType === "other"}
                  onChange={(e) => setFraudType(e.target.value as "other")}
                />
                <label
                  htmlFor="type3"
                  className="btn-default btn-3 fraud-radio"
                >
                  Other
                </label>
              </div>
            </div>
          )}
          <div className={`block-action ${submitFocus}`}>
            <button
              className="btn-primary"
              onClick={handleSubmit}
              disabled={submitted}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  let infoDiv;
  if (completed) {
    infoDiv = (
      <ul className="sidebar_jobid spoof-centered">
        <li>
          <span>Spoof Review</span>
        </li>
        <li>
          <label>Job id</label>
          <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
        </li>
        {review.first_sent_at && (
          <li>
            <label>First sent at</label>
            <span id="jobid_firstsentat">
              {new Date(review.first_sent_at).toLocaleString().split(",")[0]}
            </span>
          </li>
        )}
        <li>
          <label>Author</label>
          <span id="jobid_autor">{review.author}</span>
        </li>
      </ul>
    );
  } else {
    infoDiv = (
      <ul className="sidebar_jobid spoof-centered">
        <li>
          <span>Spoof Review</span>
        </li>
        <li>
          <label>Job id</label>
          <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
        </li>
        {review.created_at && (
          <li>
            <label>Created at</label>
            <span id="jobid_createat">
              {new Date(review.created_at).toLocaleString().split(",")[0]}
            </span>
          </li>
        )}
        {review.first_sent_at && (
          <li>
            <label>First sent at</label>
            <span id="jobid_firstsentat">
              {new Date(review.first_sent_at).toLocaleString().split(",")[0]}
            </span>
          </li>
        )}
        {review.wait_time && (
          <li>
            <label>Time left on review:</label>
            <span id="jobid_firstsentat">
              <TimeoutReview
                reviewId={review.id}
                waitTime={review.wait_time}
                onNextReview={next}
              />
            </span>
          </li>
        )}
      </ul>
    );
  }

  return (
    <section className="container__content">
      {infoDiv}
      <div className="">
        <Carousel>{carousel}</Carousel>
      </div>
      <div id="" />
      <div className="spoof-id">
        <div className={`user-id left${idFocus}`}>
          {review.id_card_front && (
            <img
              src={review.id_card_front}
              alt="id front"
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          )}
        </div>
        {spoofForm}
        <div className={`user-id right${idFocus}`}>
          {review.id_card_rear && (
            <img
              src={review.id_card_rear}
              alt="id back"
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default SpoofReview;
